import {
  apiDeleteCall,
  apiGetCall,
  apiPostCall,
  apiPutCall,
  getHeaders,
  handleSessionTimeout,
  isTokenExpired,
} from "../../utils/utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

export const getShipmentBundle = createAsyncThunk(
  "getShipmentBundle",
  async (data, handleSuccess, handleFail) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${
          process.env.REACT_APP_API_ENDPOINT
        }api/shipment_list/?paginate=true&page=${data?.pageIndex}&limit=10${
          data?.orderStatus && data?.orderStatus?.length
            ? `&status=${JSON.stringify(data?.orderStatus)}`
            : ""
        }${
          data?.addressType?.length
            ? `&address_filter=${data?.addressType}`
            : ""
        }&is_buyer=${data?.currentUserType === "buyer" ? "true" : "false"}`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const getShipmentCards = createAsyncThunk(
  "getShipmentCards",
  async (data, handleSuccess, handleFail) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/shipping_info/?shipment_id=${
          data.id
        }&is_buyer=${data.user === "buyer" ? true : false}`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const getTrackingStatus = createAsyncThunk(
  "getTrackingStatus",
  async (data, handleSuccess, handleFail) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/tracking_status/?shipment_id=${data}`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const getRecentTransections = createAsyncThunk(
  "getRecentTransections",
  async (data, handleSuccess, handleFail) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/payment_status/?buyer=${
          data?.currentUserType === "buyer" ? true : false
        }&paginate=true&page=${data.pageIndex}&limit=10${
          data && data?.transectionStatus?.length
            ? `&filter=${data.transectionStatus}`
            : ""
        }`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const getRecentPayouts = createAsyncThunk(
  "getRecentPayouts",
  async (data, handleSuccess, handleFail) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${
          process.env.REACT_APP_API_ENDPOINT
        }api/seller_payout/?paginate=true&page=${data.pageIndex}&limit=10${
          data?.start && data?.end
            ? `&start_date=${data?.start}&end_date=${data?.end}`
            : ""
        }${data?.sort?.length ? `&order_by=${data?.sort}` : ""}${
          data?.min ? `&min_price=${data?.min}` : ""
        }${data?.max ? `&max_price=${data?.max}` : ""}`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const getRecentPayoutDetails = createAsyncThunk(
  "getRecentPayoutDetails",
  async (data, handleSuccess, handleFail) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${
          process.env.REACT_APP_API_ENDPOINT
        }api/seller_payout_details/?payout_id=${data?.id}${
          data?.searchQuery
            ? `&search_text=${encodeURIComponent(data?.searchQuery)}`
            : ""
        }`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const getRecentTransectionsDetails = createAsyncThunk(
  "getRecentTransectionsDetails",
  async (data, handleSuccess, handleFail) => {
    if (isTokenExpired()) handleSessionTimeout();
    else
      return await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}api/tax_breakdown/?is_buyer=${
          data?.user === "buyer" ? true : false
        }&card_id=${data?.id}`,
        {
          headers: getHeaders(),
        }
      );
  }
);

export const addAddress = (userData, handleSuccess, handleFail) => {
  apiPostCall("api/paymentCards/", userData, handleSuccess, handleFail);
};

export const updateAddress = (userData, handleSuccess, handleFail) => {
  apiPutCall("api/paymentCards/", userData, handleSuccess, handleFail);
};

export const deleteAddress = (userData, handleSuccess, handleFail) => {
  let url = `?address_id=${userData.id}`;
  apiDeleteCall(`api/paymentCards/${url}`, handleSuccess, handleFail);
};

export const getBuyerPaymentReceipt = (
  bundleShipmentId,
  handleSuccess,
  handleFail
) => {
  apiGetCall(
    `api/invoice/buyer/?bundle_shipment_id=${bundleShipmentId}`,
    handleSuccess,
    handleFail
  );
};

export const getBuyerRefundReceipt = (
  bundleShipmentId,
  handleSuccess,
  handleFail
) => {
  apiGetCall(
    `api/invoice/refund/buyer/?bundle_shipment_id=${bundleShipmentId}`,
    handleSuccess,
    handleFail
  );
};

export const getSellerPaymentReceipt = (
  payoutId,
  handleSuccess,
  handleFail
) => {
  apiGetCall(`api/invoice/seller/?id=${payoutId}`, handleSuccess, handleFail);
};

const trackingStatus = createSlice({
  name: "trackingStatus",
  initialState: {
    isLoading: false,
    shipmentTrackingStatus: null,
  },

  extraReducers: (builder) => {
    builder.addCase(getTrackingStatus.pending, (state, action) => {
      state.isLoading = true;
      state.shipmentTrackingStatus = null;
    });
    builder.addCase(getTrackingStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.shipmentTrackingStatus = action.payload?.data;
    });
    builder.addCase(getTrackingStatus.rejected, (state, action) => {
      state.isLoading = false;
      state.shipmentTrackingStatus = action.error;
    });
  },
});
const shipmentCards = createSlice({
  name: "shipmentCards",
  initialState: {
    isLoading: false,
    allShipmentCards: null,
  },

  extraReducers: (builder) => {
    builder.addCase(getShipmentCards.pending, (state, action) => {
      state.isLoading = true;
      state.allShipmentCards = null;
    });
    builder.addCase(getShipmentCards.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allShipmentCards = action.payload?.data;
    });
    builder.addCase(getShipmentCards.rejected, (state, action) => {
      state.isLoading = false;
      state.allShipmentCards = action.error;
    });
  },
});

const shipmentBundle = createSlice({
  name: "shipmentBundle",
  initialState: {
    isLoading: false,
    allShipmentBundle: null,
    paymentReceipt: "",
  },
  extraReducers: (builder) => {
    builder.addCase(getShipmentBundle.pending, (state, action) => {
      state.isLoading = true;
      state.allShipmentBundle = null;
    });
    builder.addCase(getShipmentBundle.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allShipmentBundle = action.payload?.data;
    });
    builder.addCase(getShipmentBundle.rejected, (state, action) => {
      state.isLoading = false;
      state.allShipmentBundle = action.error;
    });
  },
});
const recentTransections = createSlice({
  name: "recentTransections",
  initialState: {
    isLoading: false,
    allRecentTransections: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getRecentTransections.pending, (state, action) => {
      state.isLoading = true;
      state.allRecentTransections = null;
    });
    builder.addCase(getRecentTransections.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allRecentTransections = action.payload?.data;
    });
    builder.addCase(getRecentTransections.rejected, (state, action) => {
      state.isLoading = false;
      state.allRecentTransections = action.error;
    });
  },
});

const recentPayouts = createSlice({
  name: "recentPayouts",
  initialState: {
    isLoading: false,
    allRecentPayouts: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getRecentPayouts.pending, (state, action) => {
      state.isLoading = true;
      state.allRecentPayouts = null;
    });
    builder.addCase(getRecentPayouts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allRecentPayouts = action.payload?.data;
    });
    builder.addCase(getRecentPayouts.rejected, (state, action) => {
      state.isLoading = false;
      state.allRecentPayouts = action.error;
    });
  },
});
const recentPayoutDetails = createSlice({
  name: "recentPayoutDetails",
  initialState: {
    isLoading: false,
    recentPayoutDetail: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getRecentPayoutDetails.pending, (state, action) => {
      state.isLoading = true;
      state.recentPayoutDetail = null;
    });
    builder.addCase(getRecentPayoutDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.recentPayoutDetail = action.payload?.data;
    });
    builder.addCase(getRecentPayoutDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.recentPayoutDetail = action.error;
    });
  },
});

const recentTransectionsDetails = createSlice({
  name: "recentTransectionsDetails",
  initialState: {
    isLoading: false,
    recentTransectionsDetail: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getRecentTransectionsDetails.pending, (state, action) => {
      state.isLoading = true;
      state.recentTransectionsDetail = null;
    });
    builder.addCase(getRecentTransectionsDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.recentTransectionsDetail = action.payload?.data;
    });
    builder.addCase(getRecentTransectionsDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.recentTransectionsDetail = action.error;
    });
  },
});

const shipment = {
  shipmentBundle: shipmentBundle.reducer,
  shipmentCards: shipmentCards.reducer,
  trackingStatus: trackingStatus.reducer,
  recentTransections: recentTransections.reducer,
  recentPayouts: recentPayouts.reducer,
  recentPayoutDetails: recentPayoutDetails.reducer,
  recentTransectionsDetails: recentTransectionsDetails.reducer,
};
export default shipment;
